import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { LoginService } from './login.service';
import { SaleExtract } from '../models/sale';

@Injectable()
export class StatementService {

  private URL: string = environment.urlbase + 'purchases/extract';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  myPerPeriod(period: string): Observable<SaleExtract[]> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<SaleExtract[]>(this.URL, { headers: this.headers, params: { date: period } });
  }

}
