import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Package } from '../models/package';
import { LoginService } from './login.service';


@Injectable()
export class PackagesService {

  private URL: string = environment.urlbase + 'packages';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<Package[]> {
    return this.http.get<Package[]>(this.URL, {headers: this.headers});
  }

  update(model: Package): Observable<Package> {
    return this.http.put<Package>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: Package): Observable<Package> {
    return this.http.post<Package>(this.URL, model, {headers: this.headers});
  }

  delete(model: Package): Observable<Package> {
    return this.http.delete<Package>(this.URL + '/' + model.id, {headers: this.headers});
  }
}
