import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { GroupLesson } from '../models/grouplesson';
import { LoginService } from './login.service';
import { Product } from '../models/product';
import { Lesson } from '../models/lesson';
import { Answer } from '../models/answer';

@Injectable()
export class LessonsService {

  private URL: string = environment.urlbase + 'products/';
  private URLext: string = '/grouplessons/';
  private URLextext: string = '/lessons';
  private answerURL: string = environment.urlbase + 'answers/';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(grandParent: Product | number, parent: GroupLesson | number): Observable<Lesson[]> {
    let lessonURL: string = this.URL + (typeof(grandParent) == 'number' ? grandParent : grandParent.id) + this.URLext;
    lessonURL += (typeof(parent) == 'number' ? parent : parent.id) + this.URLextext;
    return this.http.get<Lesson[]>(lessonURL, {headers: this.headers});
  }

  show(grandParent: Product | number, parent: GroupLesson | number, model: Lesson | number): Observable<Lesson> {
    let lessonURL: string = this.URL + (typeof(grandParent) == 'number' ? grandParent : grandParent.id) + this.URLext;
    lessonURL += (typeof(parent) == 'number' ? parent : parent.id) + this.URLextext + '/';
    return this.http.get<Lesson>(lessonURL + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  update(grandParent: Product | number, parent: GroupLesson | number, model: Lesson): Observable<Lesson> {
    let lessonURL: string = this.URL + (typeof(grandParent) == 'number' ? grandParent : grandParent.id) + this.URLext;
    lessonURL += (typeof(parent) == 'number' ? parent : parent.id) + this.URLextext + '/';
    return this.http.put<Lesson>(lessonURL + model.id, model, {headers: this.headers});
  }

  store(grandParent: Product | number, parent: GroupLesson | number, model: Lesson): Observable<Lesson> {
    let lessonURL: string = this.URL + (typeof(grandParent) == 'number' ? grandParent : grandParent.id) + this.URLext;
    lessonURL += (typeof(parent) == 'number' ? parent : parent.id) + this.URLextext;
    return this.http.post<Lesson>(lessonURL, model, {headers: this.headers});
  }

  delete(grandParent: Product | number, parent: GroupLesson | number, model: Lesson | number): Observable<Lesson> {
    let lessonURL: string = this.URL + (typeof(grandParent) == 'number' ? grandParent : grandParent.id) + this.URLext;
    lessonURL += (typeof(parent) == 'number' ? parent : parent.id) + this.URLextext + '/';
    return this.http.delete<Lesson>(lessonURL + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  deleteAnswer(model: number | Answer) : Observable<any> {
    return this.http.delete<any>(this.answerURL + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

}
