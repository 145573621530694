import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient } from '@angular/common/http';
import { MapResult } from '../models/map.result';

@Injectable()
export class MapService {

  private googleMapsAPI: string = 'https://maps.google.com/maps/api/';
  private outputAndKey: string = '/json?key=AIzaSyCwSgMeADrWVl8WPrEkg5blKZLBtAKRfHQ&';
  private URL: string = this.googleMapsAPI + 'geocode' + this.outputAndKey;
  private timezone: string = 'https://maps.googleapis.com/maps/api/timezone' + this.outputAndKey;

  constructor(
    private http: HttpClient
  ) {}

  getCoords(address: string): Observable<MapResult> {
    return this.http.get<MapResult>(this.URL + 'address=' + address);
  }

  getAddress(lat: number | string, lng: number | string): Observable<MapResult> {
    return this.http.get<MapResult>(this.URL + 'latlng=' + lat + ',' + lng);
  }

  getTimeZone(lat: number | string, lng: number | string): Observable<MapResult> {
    const timestamp: number = Math.floor(Date.now() / 1000);
    return this.http.get<MapResult>(this.timezone + 'location=' + lat + ',' + lng + '&timestamp=' + timestamp);
  }

}
