import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';

import { LoginService } from './login.service';
import { Coupon } from '../models/coupon';

import { environment } from '../../../environments/environment';
import 'rxjs/add/observable/of';

@Injectable()
export class CouponsService {

  private URL: string = environment.urlbase + 'coupons';
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<Coupon[]> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Coupon[]>(this.URL, {headers: this.headers});
  }

  update(model: Coupon): Observable<Coupon> {
    this.headers = this.loginService.getHeaders();
    return this.http.put<Coupon>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: Coupon): Observable<Coupon> {
    this.headers = this.loginService.getHeaders();
    return this.http.post<Coupon>(this.URL, model, {headers: this.headers});
  }

  delete(model: Coupon): Observable<any> {
    this.headers = this.loginService.getHeaders();
    return this.http.delete<any>(this.URL + '/' + model.id , {headers: this.headers});
  }

  find(id: number): Observable<Coupon> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Coupon>(this.URL + '/' + id, {headers: this.headers});
  }

  detail(model: Coupon | number | string): Observable<Coupon> {
    this.headers = this.loginService.getHeaders();
    return this.find((typeof(model) == 'number' ? model : (typeof(model) == 'string' ? parseInt(model) : model.id)));
  }

  get(model: Coupon | number | string): Observable<Coupon> {
    this.headers = this.loginService.getHeaders();
    return this.find((typeof(model) == 'number' ? model : (typeof(model) == 'string' ? parseInt(model) : model.id)));
  }
}
