import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { LoginService } from './@core/data/login.service';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private login: LoginService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>(can => {
      this.login.check().then(
        logged => {
          if (logged) {
            can(true);
          } else {
            this.router.navigate(['/logout']);
            can(false);
          }
        }
      )
    });
  }

}
