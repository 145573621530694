import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { GroupLesson } from '../models/grouplesson';
import { GroupLessonCopy } from '../models/grouplessioncopy';
import { LoginService } from './login.service';
import { Product } from '../models/product';
import { Lesson } from '../models/lesson';

@Injectable()
export class GroupLessonsService {

  private URL: string = environment.urlbase + 'products/';
  private URLext: string = '/grouplessons';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(parent: Product | number): Observable<GroupLesson[]> {
    const groupURL: string = this.URL + (typeof(parent) == 'number' ? parent : parent.id);
    return this.http.get<GroupLesson[]>((groupURL + this.URLext), {headers: this.headers});
  }

  show(parent: Product | number, model: GroupLesson | number): Observable<GroupLesson> {
    const groupURL: string = this.URL + (typeof(parent) == 'number' ? parent : parent.id) + this.URLext + '/';
    return this.http.get<GroupLesson>(groupURL + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  update(parent: Product | number, model: GroupLesson): Observable<GroupLesson> {
    const groupURL: string = this.URL + (typeof(parent) == 'number' ? parent : parent.id) + this.URLext + '/';
    return this.http.put<GroupLesson>(groupURL + model.id, model, {headers: this.headers});
  }

  store(parent: Product | number, model: GroupLesson): Observable<GroupLesson> {
    const groupURL: string = this.URL + (typeof(parent) == 'number' ? parent : parent.id) + this.URLext;
    return this.http.post<GroupLesson>(groupURL, model, {headers: this.headers});
  }

  delete(parent: Product | number, model: GroupLesson | number): Observable<GroupLesson> {
    const groupURL: string = this.URL + (typeof(parent) == 'number' ? parent : parent.id) + this.URLext + '/';
    return this.http.delete<GroupLesson>(groupURL + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }
  copy(product_id: number, model: GroupLessonCopy): Observable<GroupLessonCopy> {
    const groupURL: string = this.URL + product_id + this.URLext + '/copy';
    return this.http.post<GroupLessonCopy>(groupURL, model, {headers: this.headers});
  }
}
