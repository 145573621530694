import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Live } from '../models/live';
import { LoginService } from './login.service';

@Injectable()
export class LivesService {

  private URL: string = environment.urlbase + 'lives';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<Live[]> {
    return this.http.get<Live[]>(this.URL, {headers: this.headers});
  }

  show(model: Live | number): Observable<Live> {
    return this.http.get<Live>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  update(model: Live): Observable<Live> {
    return this.http.put<Live>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: Live): Observable<Live> {
    return this.http.post<Live>(this.URL, model, {headers: this.headers});
  }

  delete(model: Live): Observable<Live> {
    return this.http.delete<Live>(this.URL + '/' + model.id, {headers: this.headers});
  }

}
