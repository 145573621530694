import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { CookieService } from 'angular2-cookie/services/cookies.service';

let counter = 0;

@Injectable()
export class UserService {

  private users: any = {
    nick: { name: 'Alexandre', picture: 'assets/images/alexandre_perfil.jpg' },
    eva: { name: 'Eva Moor', picture: 'assets/images/eva.png' },
    jack: { name: 'Jack Williams', picture: 'assets/images/jack.png' },
    lee: { name: 'Lee Wong', picture: 'assets/images/lee.png' },
    alan: { name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    kate: { name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  };

  private userArray: any[];

  constructor(
    private cookieService: CookieService,
  ) { }

  getUsers(): Observable<any> {
    var users = {
      nick: {
        name: this.cookieService.get('flightclass_admin__name') ? this.cookieService.get('flightclass_admin__name') : null,
        picture: this.cookieService.get('flightclass_admin__picture') ? this.cookieService.get('flightclass_admin__picture') : null
      }
    }
    return Observable.of(users);
  }

  getUserArray(): Observable<any[]> {
    return Observable.of(this.userArray);
  }

  getUser(): Observable<any> {
    counter = (counter + 1) % this.userArray.length;
    return Observable.of(this.userArray[counter]);
  }
}
