import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';
import { StateService } from './state.service';

import { SmartTableService } from './smart-table.service';
import { MapService } from './map.service';
import { StatementService } from './statement.service';
import { CookieService } from 'angular2-cookie/core';
import { LayoutService } from './layout.service';
import { SmartTableFilterService } from './smart-table-filter.service';
import { StorageService } from './storage.service';

import { AppUsersService } from './app.users.service';
import { AdminUsersService } from './admin.users.service';
import { LoginService } from './login.service';
import { ProductsService } from './products.service';
import { GroupLessonsService } from './grouplessons.service';
import { LessonsService } from './lessons.service';
import { BucketService } from './bucket.service';
import { PackagesService } from './packages.service';
import { SettingsService } from './settings.service';
import { SubscriptionPackagesService } from './subscription.packages.service';
import { PushNotificationsService } from './push.notifications.service';
import { LivesService } from './lives.service';
import { GeneralService } from './general.service';
import { URLGeneratorService } from './url-generator.service';
import { CouponsService } from './coupons.service';

export function cookieServiceFactory() {
  return new CookieService();
}

const SERVICES = [
  UserService,
  StateService,
  SmartTableService,
  MapService,
  StatementService,
  LayoutService,
  SmartTableFilterService,
  StorageService,
  LoginService,
  AppUsersService,
  AdminUsersService,
  ProductsService,
  GroupLessonsService,
  LessonsService,
  SettingsService,
  PackagesService,
  BucketService,
  SubscriptionPackagesService,
  PushNotificationsService,
  LivesService,
  GeneralService,
  URLGeneratorService,
  CouponsService,
  { provide: CookieService, useFactory: cookieServiceFactory }
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class DataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
