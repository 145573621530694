import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CookieService } from 'angular2-cookie/services/cookies.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoginService {

  private URL: string = environment.urlbase + 'login';
  private logged: boolean = false;

  private roles: Object = {
    ADMIN: 'Manager',
    MARKETING: 'Marketing',
    PRODUCTS: 'Product',
    OPERATOR: 'Client',
    undefined: 'Manager'
  }

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) { }

  check(): Promise<boolean> {
    return new Promise<boolean>(
      (logged: any): void => {
        if (this.logged) {
          logged(true);
        } else {
          const email: string = this.cookieService.get('flightclass_admin__email');
          const password: string = this.cookieService.get('flightclass_admin__password');
          const role: string = this.cookieService.get('flightclass_admin__role');
          if (role !== undefined && role !== 'Manager') {
            logged(false);
          } else if (email !== undefined && password !== undefined) {
            this.login(email, password)
            .then(
              (valid: boolean): void => {
                logged(valid);
              },
            );
          } else {
            logged(false);
          }
        }
      },
    );
  }

  login(email: string, password: string): Promise<boolean> {
    return new Promise<boolean>(
      (logged: any): void => {
        const options = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Accept': 'application/json'
          })
        };
        this.http.post(this.URL, { email: email, password: password }, options)
        .subscribe(
          (auth: any): void => {
            console.log(auth);
            this.logged = true;
            this.cookieService.put('flightclass_admin__email', email);
            this.cookieService.put('flightclass_admin__password', password);
            this.cookieService.put('flightclass_admin__name', 'Flight Class');
            this.cookieService.put('flightclass_admin__picture', 'assets/images/cockpit.jpeg');
            this.cookieService.put('flightclass_admin__role', this.roles[auth.type]);
            this.cookieService.put('flightclass_admin__address', '');
            this.cookieService.put('flightclass_admin__token', auth.token);
            logged(true);
          },
          (): void => {
            this.logged = false;
            this.purge();
            logged(false);
          },
        );
      },
    );
  }

  logout(): void {
    this.logged = false;
    this.purge();
  }

  private purge(): void {
    this.cookieService.remove('flightclass_admin__email');
    this.cookieService.remove('flightclass_admin__password');
    this.cookieService.remove('flightclass_admin__name');
    this.cookieService.remove('flightclass_admin__picture');
    this.cookieService.remove('flightclass_admin__role');
    this.cookieService.remove('flightclass_admin__address');
    this.cookieService.remove('flightclass_admin__token');
  }

  checkPermission(role: string[]): Promise<boolean> {
    return new Promise<boolean>(
      (can: any): void => {
        const loggedUserRole: string = this.cookieService.get('flightclass_admin__role');
        let permission: boolean = false;
        for (let i: number = 0; i< role.length; i++) {
          permission = permission || loggedUserRole == role[i];
        }
        can(permission);
      }
    );
  }

  userRole(): string {
    return this.cookieService.get('flightclass_admin__role');
  }

  getToken(): string {
    return this.cookieService.get('flightclass_admin__token');
  }

  getHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      'Access-Control-Allow-Origin':'*',
      'Accept': 'application/json'
    });
    const token = this.getToken();
    if (token !== undefined && token !== '' && token !== null) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return headers;
  }
}
