import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Category } from '../models/category';
import { Timeline } from '../models/timeline';
import { LoginService } from './login.service';

@Injectable()
export class GeneralService {

  private URL: string = environment.urlbase.replace('admin/', 'app/timeline');

  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  pluralMe(modelSingular: string): string {
    if (modelSingular === null || modelSingular === undefined || modelSingular == '') return '';
    const wordGroups = modelSingular.split(' ');
    wordGroups[0] = wordGroups[0].slice(-2) == 'ão' ? (wordGroups[0].replace(/.$/, '').replace(/.$/, '') + 'ões') : (
      wordGroups[0].slice(-1) == 'r' ? (wordGroups[0] + 'es') : (
        wordGroups[0].slice(-1) == 'm' ? (wordGroups[0].replace(/.$/, '') + 'ns') : (wordGroups[0] + 's')
    ));
    return wordGroups.join(' ');
  }

  timeline(): Observable<Timeline> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Timeline>(this.URL, {headers: this.headers})
  }

  defaultMainCategory(): Category {
    return   {
      "name": "treinamentos",
      "title": "TREINAMENTOS",
      "short_desc": "Para pilotos e comissários",
      "order": 1,
      "col": 2,
      "logged": false,
      "questions": false,
      "lessons": true
    }
  }

  defaultPracticeTestCategory(): Category {
    return  {
      "name": "simulados",
      "title": "SIMULADOS",
      "short_desc": "Teste o seu conhecimento",
      "order": 4,
      "col": 4,
      "logged": true,
      "questions": true,
      "lessons": false
    }
  }

}
