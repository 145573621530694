import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Settings } from '../models/settings';

import { LoginService } from './login.service';
import { GeneralService } from './general.service';
import { Timeline } from '../models/timeline';

@Injectable()
export class SettingsService {

  private URL: string = environment.urlbase + 'config';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private generalService: GeneralService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  get(): Observable<Settings> {
    return this.http.get<Settings>(this.URL, {headers: this.headers});
  }

  update(model: Settings): Observable<Settings> {
    return this.http.post<Settings>(this.URL, model, {headers: this.headers});
  }

  programTypes(): string[] {
    let types: string[];
    this.generalService.timeline()
    .subscribe(
      (done: Timeline): void => {
        types = done.categories.map((x: any): string => x.title);
      },
      (): void => {
        types = [];
      }
    )
    return types;
  }

  practiceTest(): string {
    return (this.programTypes())[3];
  }

  programFancyTypes(): string[] {
    return ['RQX Mentalidade', 'RQX Treino', 'RQX Nutrição', 'Simulados'];
  }
}
