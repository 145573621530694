import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { PushNotification } from '../models/push.notification';
import { LoginService } from './login.service';

@Injectable()
export class PushNotificationsService {

  private URL: string = environment.urlbase + 'pushnotifications';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<PushNotification[]> {
    return this.http.get<PushNotification[]>(this.URL, {headers: this.headers});
  }

  update(model: PushNotification): Observable<PushNotification> {
    return this.http.put<PushNotification>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: PushNotification): Observable<PushNotification> {
    return this.http.post<PushNotification>(this.URL, model, {headers: this.headers});
  }

  delete(model: PushNotification): Observable<PushNotification> {
    return this.http.delete<PushNotification>(this.URL + '/' + model.id, {headers: this.headers});
  }

  cancel(model: PushNotification): Observable<PushNotification[]> {
    return this.http.post<PushNotification[]>(this.URL + '/' + model.id + '/cancel', model, {headers: this.headers});
  }
}
