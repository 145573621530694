import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { Product } from '../models/product';
import { Lesson } from '../models/lesson';
import { LoginService } from './login.service';

@Injectable()
export class ProductsService {

  private URL: string = environment.urlbase + 'products';
  private URLcsv: string = environment.urlbase + 'exams/export';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<Product[]> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Product[]>(this.URL, {headers: this.headers});
  }

  show(model: Product | number): Observable<Product> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Product>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  update(model: Product): Observable<Product> {
    this.headers = this.loginService.getHeaders();
    return this.http.put<Product>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: Product): Observable<Product> {
    this.headers = this.loginService.getHeaders();
    return this.http.post<Product>(this.URL, model, {headers: this.headers});
  }

  delete(model: Product): Observable<Product> {
    this.headers = this.loginService.getHeaders();
    return this.http.delete<Product>(this.URL + '/' + model.id, {headers: this.headers});
  }

  videos(model: Product | number): Observable<Lesson[]> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<Lesson[]>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id) + '/videos', {headers: this.headers});
  }

  donwload(): Observable<HttpResponse<Blob>> {
    this.headers = this.loginService.getHeaders();
    return this.http.get(this.URLcsv, {headers: this.headers, observe: 'response', responseType: 'blob'});
  }

}
