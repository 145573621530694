import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { AdminUser } from '../models/admin.user';
import { LoginService } from './login.service';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AdminUsersService {

  private URL: string = environment.urlbase + 'users';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<AdminUser[]> {
    return this.http.get<AdminUser[]>(this.URL, {headers: this.headers});
  }

  show(model: AdminUser | number): Observable<AdminUser> {
    return this.http.get<AdminUser>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  update(model: AdminUser): Observable<AdminUser> {
    return this.http.put<AdminUser>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: AdminUser): Observable<AdminUser> {
    return this.http.post<AdminUser>(this.URL, model, {headers: this.headers});
  }

  delete(model: AdminUser): Observable<AdminUser> {
    return this.http.delete<AdminUser>(this.URL + '/' + model.id, {headers: this.headers});
  }

  search(target: string): Observable<AdminUser[]> {
    return this.http.get<AdminUser[]>(this.URL + '?search=' + encodeURI(target), {headers: this.headers});
  }

  getExcelToken(): Observable<any> {
    return this.http.get<any>(this.URL + '/token', {headers: this.headers});
  }



}
