import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import { AppUser } from '../models/app.user';
import { LoginService } from './login.service';
import { Purchase } from '../models/purchase';
import { Device } from '../models/device';
import { Product } from '../models/product';
import { SubscriptionPackage } from '../models/subscription.package';
import { AvailableProduct } from '../models/available.product';

@Injectable()
export class AppUsersService {

  private URL: string = environment.urlbase + 'appusers';
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {
    this.headers = this.loginService.getHeaders();
  }

  all(): Observable<AppUser[]> {
    return this.http.get<AppUser[]>(this.URL, {headers: this.headers});
  }

  show(model: AppUser | number): Observable<AppUser> {
    return this.http.get<AppUser>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id), {headers: this.headers});
  }

  update(model: AppUser): Observable<AppUser> {
    return this.http.put<AppUser>(this.URL + '/' + model.id, model, {headers: this.headers});
  }

  store(model: AppUser): Observable<AppUser> {
    return this.http.post<AppUser>(this.URL, model, {headers: this.headers});
  }

  delete(model: AppUser): Observable<AppUser> {
    return this.http.delete<AppUser>(this.URL + '/' + model.id, {headers: this.headers});
  }

  purchases(model: AppUser | number): Observable<Purchase[]> {
    return this.http.get<Purchase[]>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id) + '/purchases', {headers: this.headers});
  }

  devices(model: AppUser | number): Observable<Device[]> {
    return this.http.get<Device[]>(this.URL + '/' + (typeof(model) == 'number' ? model : model.id) + '/devices', {headers: this.headers});
  }

  destroyDevice(model: AppUser, device: Device){
    this.headers = this.loginService.getHeaders();
    const deviceUri: string =  (typeof(model) == 'number' ? model : model.id) + '/devices/' + (typeof(device) == 'number' ? device : device.id);
    return this.http.delete(this.URL + '/' + deviceUri, {headers: this.headers});
  }

  quiz(): Observable<AppUser[]> {
    return this.http.get<AppUser[]>(this.URL + '/quiz', {headers: this.headers});
  }

  addProductToPurchase(model: AppUser | number, child: Product | number, validateDate: string): Observable<Purchase> {
    let postURL: string = this.URL + '/' + (typeof(model) == 'number' ? model : model.id);
    postURL += '/purchases/' + (typeof(child) == 'number' ? child : child.id);
    return this.http.post<Purchase>(postURL, {validate: validateDate}, {headers: this.headers});
  }

  changeProductValidate(model: AvailableProduct): Observable<any> {
    return this.http.put<any>(environment.urlbase + 'availableproducts/' + model.id, 
      {validate: model.validate},
      {headers: this.headers}
    );
  }

  search(target: string): Observable<AppUser[]> {
    return this.http.get<AppUser[]>(this.URL + '?search=' + encodeURI(target), {headers: this.headers});
  }

  searchQuiz(target: string): Observable<AppUser[]> {
    return this.http.get<AppUser[]>(this.URL + '/quiz?search=' + encodeURI(target), {headers: this.headers});
  }

  getExcelToken(): Observable<any> {
    return this.http.get<any>(this.URL + '/token', {headers: this.headers});
  }

  downloadExcel(token: string): Observable<Blob> {
    return this.http.get(this.URL + '/export/' + token, {headers: this.headers, responseType: 'blob'});
  }

  downloadExcelQuiz(): Observable<HttpResponse<Blob>> {
    return this.http.get(this.URL + '/quiz/export', {headers: this.headers, observe: 'response', responseType: 'blob'});
  }

  subscriptions(model: AppUser | number): Observable<SubscriptionPackage[]> {
    return this.http.get<SubscriptionPackage[]>(
      this.URL + '/' + (typeof(model) == 'number' ? model : model.id) + '/subscriptions', 
      {headers: this.headers}
    );
  }

  updateSubscriptions(model: AppUser): Observable<AppUser> {
    return this.http.put<AppUser>(
      this.URL + '/' + model.id + '/subscriptions', 
      {ends_at: model.subscription_validade},
      {headers: this.headers}
    );
  }

  addSubscriptions(model: AppUser | number, child: SubscriptionPackage | number): Observable<any> {
    return this.http.post<any>(
      this.URL + '/' + (typeof(model) == 'number' ? model : model.id) + '/plans/' + (typeof(child) == 'number' ? child : child.id), 
      {},
      {headers: this.headers}
    );
  }

  deleteSubscriptions(model: AppUser | number): Observable<any> {
    return this.http.delete<any>(
      this.URL + '/' + (typeof(model) == 'number' ? model : model.id) + '/subscriptions', 
      {headers: this.headers}
    );
  }

  changePassword(model: AppUser, newPassword: string) {
    return this.http.post<any>(environment.urlbase + 'clients/' + model.id + '/change-password', { "new-password": newPassword }, { headers: this.headers });
  }
}
