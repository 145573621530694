import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoginService } from './login.service';
import { PagarmePlan } from '../models/pagarme-plan';

@Injectable()
export class URLGeneratorService {

  private URL = environment.urlbase + 'checkout/data';
  private pgURL: string = environment.urlbase + 'pagarmeplans';
  
  headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
  ) {
    this.headers = this.loginService.getHeaders();
  }

  generate(model: any): Observable<any> {
    this.headers = this.loginService.getHeaders();
    return this.http.post(this.URL, model, {headers: this.headers, responseType: 'text'});
  }

  pagarmePlans(): Observable<PagarmePlan[]> {
    this.headers = this.loginService.getHeaders();
    return this.http.get<PagarmePlan[]>(this.pgURL, {headers: this.headers});
  }
};
